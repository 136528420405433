<template>
    <div>
        <div class="contenedor">

            <rutaPagina :volver="false" :ruta="ruta" />

            <div class="row glass"  >

                <tituloPagina texto="Información para filtrar" />

                <form class="row">
                    <div class="col-lg-4 grupo-label">
                        <label for="fecha_inicio" class="form-label">Fecha</label>
                        <div class="input-group mb-3">
                            <input type="date" id="fecha_inicio" class="form-control fecha-inicio" placeholder="Fecha inicio" aria-label="Fecha inicio">
                            <span class="input-group-text spam-hasta">hasta</span>
                            <input type="date" id="fecha_fin" class="form-control fecha-fin" placeholder="Fecha fin" aria-label="Fecha fin">
                        </div>
                    </div>

                    <div class="col-lg-4 grupo-label">
                        <label for="estado" class="form-label">Estado</label>
                        <select id="estado" class="form-select form-select seleccion" aria-label=".form-select-sm example">
                            <option value="0">Todos</option>
                            <option value="1">Abiertos</option>
                            <option value="2">Cerrados</option>
                        </select>
                    </div>

                    <div class="col-lg-4 grupo-label">
                        <label for="informacion_adiciona" class="form-label">Información adicional</label>
                        <div class="input-group mb-3">                        
                            <input type="text" class="form-control input-buscar" placeholder="" aria-label="Username" aria-describedby="basic-addon1">
                            <button type="button" class="btn btn-primary input-group-text btn-buscar">Buscar</button>
                        </div>                        
                    </div>

                    
                </form>

            </div>

            <div class="row glass " >                
                <div class="contenedor-tabla">
                    <div class="table-responsive">
                        <table id="tblOrdenCompra" class="dataTable tabla-ng">
                            <thead>
                                <tr class="omitir-bordes">
                                    <th scope="col" class="col-lg-2" ># Orden</th>
                                    <th scope="col" class="col-lg-2" >Fecha</th>
                                    <th scope="col" class="col-lg-3" >Proyecto</th>
                                    <th scope="col" class="col-lg-3" >Valor</th>
                                    <th scope="col" class="col-lg-1" >Estado</th>
                                    <th scope="col" class="col-lg-1" >Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a @click="verDetalleOrden(12345)" ><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-abierto" >Abierto</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                <tr class="omitir-bordes">
                                    <td>123456789</td>
                                    <td>2020-10-20</td>
                                    <td>-Sin proyecto-</td>
                                    <td>$ 6.000.000</td>
                                    <td><span class="estado-cerrado" >Cerrado</span></td>
                                    <td>
                                        <a href=""><i class='bx bx-search-alt-2'></i></a>
                                    </td>
                                </tr>
                                                    
                            </tbody>
                        </table>
                    </div>                    
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>

import tituloPagina from '../../components/Titulopagina'
import { nextTick, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router'
import datatable from 'datatables.net-bs5'
import $ from 'jquery'
import lenguajeDT from '../../assets/js/utilidades/traduccionDataTable.json'
import rutaPagina from '@/components/RutaPagina'

export default {
    name: 'Orden de compra',
    setup(props) {   

        const router = useRouter()
        const route = useRoute()

        const cargarTabla = () => {
            nextTick(() => {
                $('#tblOrdenCompra').DataTable({
                    responsive: {
                        breakpoints: [
                        {name: 'bigdesktop', width: Infinity},
                        {name: 'meddesktop', width: 1480},
                        {name: 'smalldesktop', width: 1280},
                        {name: 'medium', width: 1188},
                        {name: 'tabletl', width: 1024},
                        {name: 'btwtabllandp', width: 848},
                        {name: 'tabletp', width: 768},
                        {name: 'mobilel', width: 480},
                        {name: 'mobilep', width: 320}
                        ]
                    },
                   "lengthMenu": [ [ 10, 25, 50, 75, 100, -1 ], [10, 25, 50, 75, 100, "Todos"] ],
                   "pageLength": 10,
                    language : JSON.parse(JSON.stringify(lenguajeDT))
                });
            });
        }

        const verDetalleOrden = (id) => {
            router.push({
                path : '/ordencompra/'+id,
                query: {
                    ...route.query,
                },
            })
        }

        const ruta = [
            { nombre : 'Documentos'},
            { nombre : 'Orden de compra'},
        ]

        onMounted(cargarTabla);

        return {  
            verDetalleOrden , ruta       
        }
    },
    components : {
        tituloPagina,
        datatable,
        rutaPagina,
    },
    
}
</script>

<style scoped>    
    
</style>